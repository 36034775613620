
import makeStyles from '@ui/utils/makeStyles';
import { CSSObject } from 'tss-react';

export default makeStyles<
{},
'setPrimary' | 'deletePhoto' | 'productTagsWrapper' | 'addProductTag' | 'favoritePhoto'
>()((theme, _params, classes) => {
  const setPrimary = {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
    display: 'none',
  } as CSSObject;

  const favoritePhoto = {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: 1,
    display: 'none',
  } as CSSObject;

  const deletePhoto = {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 2,
    display: 'none',
  } as CSSObject;

  const productTagsWrapper = {
    display: 'none',
  } as CSSObject;

  const addProductTag = {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: 1,
    display: 'none',
  } as CSSObject;

  return {
    dialogImgActions: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(3),
    },
    dialogImageInfo: { 
      position: 'absolute',
      bottom: theme.spacing(3),
      right: theme.spacing(3),
      width: '40%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    dialogImageTags: {
      position: 'absolute',
      bottom: theme.spacing(3),
      left: theme.spacing(3),
      width: '40%',
    },
    dialogPaper: {
      maxHeight: '90vh',
      width: 'auto,'
    },
    photoGridContainer: {
      
    },
    photoGridItem: {
      cursor: 'pointer',
    },
    setPrimary,
    favoritePhoto,
    deletePhoto,
    productTagsWrapper,
    addProductTag,
    photoPaper: {
      position: 'relative',
      overflow: 'hidden',
      height: '400px',
      [`&:hover .${classes.setPrimary}`]: {
        display: 'block',
      },
      [`&:hover .${classes.deletePhoto}`]: {
        display: 'block',
      },
      [`&:hover .${classes.productTagsWrapper}`]: {
        display: 'block',
      },
      [`&:hover .${classes.addProductTag}`]: {
        display: 'block',
      },
      [`&:hover .${classes.favoritePhoto}`]: {
        display: 'block',
      },
    },
    productTags: {
      position: 'absolute',
      bottom: theme.spacing(1),
      left: theme.spacing(1),
      zIndex: 1,
      maxWidth: '75%',
    },
    photoImg: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    photoUploadProgress: {
      width: 0,
      height: '100%',
      background: 'rgba(255, 255, 255, .65)',
      position: 'absolute',
      top: 0,
      right: 0,
      transition: 'width .25s',
    },
    dialog: {
      width: '80vw',
      height: '80vh',
    },
    roomLinkWrapper: {
      marginTop: theme.spacing(0.5),
    },
    productTag: {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      background: 'rgba(0, 0, 0, .5)',
      color: theme.palette.common.white,
    },
    actionButton: {
      background: 'rgba(0, 0, 0, .5) !important',
    },
    actionIcon: {
      fill: 'rgba(255, 255, 255, .7) !important',
    },
    homeIcon: {
      fill: theme.palette.common.white,
    },
    homeChip: {
      cursor: 'pointer',
      background: 'rgba(0, 0, 0, .5)', 
      color: theme.palette.common.white,
    },
    productCard: {
      color: theme.palette.common.black,
    }
  };
});
