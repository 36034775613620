import { database } from '@app/firebase';
import PhotoGrid from '@components/photo-grid/PhotoGrid';
import UserContext from '@context/UserContext';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useCommonStyles from '@styles/common.style';
import { collectionGroup, orderBy,Query, query,where } from 'firebase/firestore';
import React, { useContext, useState } from 'react';

const Photos = () => {
  const { classes: common, theme } = useCommonStyles();
  const userContext = useContext<UserContext>(UserContext);
  
  const [filter, setFilter] = useState<'all' | 'favorites'>('all');
  
  let photosQuery = query(collectionGroup(database, 'photos')
    ,where('type', 'in', ['home', 'room'], )
    ,orderBy('created', 'desc'));
  let documentPhotoPath = '';

  if (userContext?.roles?.maker) {
    photosQuery = query(collectionGroup(database, 'productTags')
      ,where('product.owner.docID', '==', userContext.user.uid)
      ,orderBy('created', 'desc'));
    documentPhotoPath = 'photo';
  }

  if (userContext?.roles?.realtor) {
    photosQuery = query(photosQuery, where('members', 'array-contains', userContext.user.uid));
  }

  return (
    <div className={common.contentSpacing}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          style={{ marginBottom: theme.spacing(2) }}
        >
          <Box
            display="flex"
            alignItems="flex-end"
          >
            <Typography
              component="h1"
              variant="h3Alt"
            >
              Photos
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        style={{ marginBottom: theme.spacing(3) }}
      >
        {/* Filters */}
        <Grid
          item
          xs={12}
          md={6}
        >
          <Stack
            direction="row"
            spacing={1}
          >
            <Chip
              label="All"
              variant={filter === 'all' ? 'filled' : 'outlined'}
              onClick={() => setFilter('all')}
            />
            <Chip
              label="Favorites"
              variant={filter === 'favorites' ? 'filled' : 'outlined'}
              onClick={() => setFilter('favorites')}
            />
          </Stack>
        </Grid>
      </Grid>
      <PhotoGrid
        useInfiniteScroll
        filter={filter}
        perPage={24}
        query={photosQuery as Query<guesthouse.Photo>}
        uploadRoles={[]}
        documentPhotoPath={documentPhotoPath}
        renderZeroMessage={() => {
          return (
            <Typography>
              Theres nothing here yet, but we&apos; notify you as soon as new photos are uploaded!
            </Typography>
          );
        }}
      />
    </div>
  );
};

export default React.memo(Photos);
