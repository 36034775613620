import { database } from '@app/firebase';
import asyncRender from '@components/async/asyncRender';
import ProductCard from '@components/cards/ProductCard';
import ConfirmDialog from '@components/dialogs/ConfirmDialog';
import ProductSearchDialog from '@components/dialogs/ProductSearchDialog';
import ImageUploader from '@components/image-uploader/ImageUploader';
import { LinkBehavior } from '@components/link-behavior/LinkBehavior';
import { makeRoomLink } from '@components/room-link/RoomLink';
import AppContext from '@context/AppContext';
import NotificationContext from '@context/NotificationContext';
import UploadContext from '@context/UploadContext';
import UserContext from '@context/UserContext';
import { ScrollElement, useInfiniteScroll } from '@hooks/useInfiniteScroll';
import useRouter from '@hooks/useRouter';
import tagCursor from '@images/cursors/Tag Filled.png';
import Sentry from '@integrations/Sentry';
import IconArrowDownward from '@mui/icons-material/ArrowDownward';
import IconClear from '@mui/icons-material/Clear';
import IconDelete from '@mui/icons-material/Delete';
import IconFavorite from '@mui/icons-material/Favorite';
import IconFavoriteBorder from '@mui/icons-material/FavoriteBorder';
import IconStar from '@mui/icons-material/Star';
import IconStarBorder from '@mui/icons-material/StarBorder';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PhotoLightBox from '@ui/components/photo-lightbox/PhotoLightBox';
import Protected from '@ui/components/protected/Protected';
import TaggedProductsImage from '@ui/components/tagged-products-image/TaggedProductsImage';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import IconHome from '@ui/icons/imaterial/home/Home.svg';
import { convertPhotoToPrimaryPhoto } from '@ui/utils/convertPhotoToPrimaryPhoto';
import makeStoreUrl from '@ui/utils/makeStoreUrl';
import shortAddress from '@ui/utils/shortAddress';
import slugOrID from '@ui/utils/slugOrID';
import downloadFile from '@utils/downloadFile';
import newId from '@utils/newId';
import {
  collection, collectionGroup, deleteDoc,deleteField,doc as firestoreDoc, DocumentSnapshot, getDocs, limit, onSnapshot,
  orderBy, query as firestoreQuery, QuerySnapshot, setDoc,startAfter, updateDoc, where } from 'firebase/firestore';
import round from 'lodash.round';
import { fit } from 'object-fit-math';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Props, { defaultProps } from './PhotoGrid.props';
import PhotoGridSkeleton from './PhotoGrid.skeleton';
import useStyles from './PhotoGrid.style';
import ProductTags from './ProductTags';
import RoomSelect from './RoomSelect';
import TaggedProducts from './TaggedProducts';

type PagedPhotosPage = {
  collection?: QuerySnapshot<guesthouse.Photo>;
  photos?: guesthouse.Photo[];
  status: 'loading' | 'error' | 'complete';
  end?: boolean;
}

type PagedPhotos = {
  [key: number]: PagedPhotosPage;
}

const extractHomeRoomFromCollectionPath = (collectionPath: string) => {
  let homeId = null;
  let roomId = null;
  let photosCollection = null;

  if (collectionPath) {
    const homesRegexMatch = collectionPath.match(/homes\/([\w]+)\/photos/);
    const roomsRegexMatch = collectionPath.match(/homes\/([\w]+)\/rooms\/([\w]+)\/photos/);

    if (homesRegexMatch) {
      homeId = homesRegexMatch[1];
      photosCollection = homesRegexMatch[0];
    } else if (roomsRegexMatch) {
      homeId = roomsRegexMatch[1];
      roomId = roomsRegexMatch[2];
      photosCollection = roomsRegexMatch[0];
    }
  }

  return { homeId, roomId, photosCollection };
};

interface PhotoGridRouterParams {
  photoID: string;
}

const PhotoGrid: React.FC<Props> = (props: Props) => {
  const { classes, theme } = useStyles({});
  const [open, setOpen] = useState(false);
  const endOfContainerElement = useRef<HTMLDivElement>();
  const [selectedPhoto, setSelectedPhoto] = useState<guesthouse.Photo>();
  const [selectedProductTag, setSelectedProductTag] = useState<guesthouse.ProductTag | undefined>();
  const [nextPage, setNextPage] = useState(1);
  const [photos, setPhotos] = useState<PagedPhotos>({});
  const uploadContext = useContext<UploadContext>(UploadContext);
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const userContext = useContext<UserContext>(UserContext);
  const appContext = useContext<AppContext>(AppContext);
  const { uploads } = uploadContext;
  const { home, room, roomSet, product, showHomeInfo, showProductTags, enableTagging } = props;
  const { photosCollection } = extractHomeRoomFromCollectionPath(props.room ? `homes/${home.docID}/photos` : props.collection);
  const roomLink = useMemo(() => makeRoomLink({ home: selectedPhoto?.home, room: selectedPhoto?.room }), [selectedPhoto]);
  const router = useRouter<PhotoGridRouterParams>();
  const { sm } = useBreakpoints();
  const [baseRoute] = useState(router.location.pathname.split('/photos')[0]);

  useEffect(() => {
    setSelectedProductTag(undefined);
    try {
      const pid = selectedPhoto?.docID;

      const parts = [baseRoute, 'photos', pid];
      const route = '/' + parts.map(part => String(part).split('/').filter(Boolean)).flat().join('/');

      if (pid) {
        window.history.pushState({}, null, route);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [selectedPhoto]);

  useEffect(() => {
    const pid = router.params.photoID;

    if (pid && props.openPhotoDialog) {


      getDocs(firestoreQuery(
        collectionGroup(database, 'photos')
        ,where('docID', '==', pid)
        ,limit(1)
      ))
        .then((snap) => {
          snap.forEach((doc) => {
            const photo = doc.data() as guesthouse.Photo;

            if (photo) {
              setSelectedPhoto(photo);
              setOpen(true);
            }
          });
        });
    }
  }, []);


  const photosFlattened = useMemo(() => {
    return Object.keys(photos)
      .reduce((acc, cur) => {
        return [...acc, ...(photos[cur]?.photos || [])];
      }, [])
      .filter(Boolean);
  }, [photos]);

  let query = typeof props.collection === 'string'
    ? firestoreQuery(collection(database, props.collection), orderBy('created', 'desc'))
    : props.query;


  const getPhotoData = (doc): guesthouse.Photo => {
    let photo: guesthouse.Photo;

    if (props.documentPhotoPath) {
      photo = doc.data()[props.documentPhotoPath] as guesthouse.Photo;
    } else {
      photo = doc.data() as guesthouse.Photo;
    }

    return photo;
  };

  const onDrop = useCallback(async (files: File[]) => {
    for (const file of files) {
      const id = newId();
      const reader = new FileReader();
      const img = new Image();

      reader.addEventListener('load', function () {
        img.src = typeof reader.result === 'string' ? reader.result : String.fromCharCode.apply(null, new Uint16Array(reader.result));
      }, false);

      const imgDimensionsPromise = new Promise<{ height: number, width: number, aspectRatio: number }>((resolve) => {
        img.onload = () => {
          const height = img.naturalHeight || img.height;
          const width = img.naturalWidth || img.width;

          resolve({
            height,
            width,
            aspectRatio: +((height / width).toFixed(3))
          });
        };
      });

      if (file) {
        try {
          reader.readAsDataURL(file);
        } catch (e) {
          notificationContext.setContext({ open: true, message: 'Failed Photo Upload' });
          throw new Error('Failed Photo Upload');
        }

        try {
          const { height, width, aspectRatio } = await imgDimensionsPromise;

          if (!aspectRatio || isNaN(aspectRatio)) {
            notificationContext.setContext({ open: true, message: 'Failed Photo Upload' });
            throw new Error('Failed Photo Upload');
          }

          uploadContext.setContext({
            key: id,
            state: {
              preview: reader.result,
              status: 'Pending',
              collection: props.collection,
              file: file,
              home: home,
              room: room,
              roomSet: roomSet,
              product: product,
              height: height,
              width: width,
              file_type: file.type,
              aspectRatio
            }
          });
        } catch (e) {
          notificationContext.setContext({ open: true, message: 'Failed Photo Upload' });
          throw new Error('Failed Photo Upload');
        }
      }
    }
  }, []);

  const getPage = () => {
    if (photos[nextPage]) {
      return;
    }

    if (nextPage > 1 && !photos[nextPage - 1]) {
      return;
    }

    setPhotos(prevState => ({
      ...prevState,
      [nextPage]: {
        status: 'loading',
      },
    }));

    if (nextPage > 1) {
      const lastPage = photos[nextPage - 1];
      const lastDoc = lastPage.collection.docs[lastPage.collection.docs.length - 1];

      query = firestoreQuery(query, startAfter(lastDoc));
    }

    query = firestoreQuery(query, limit(props.perPage));

    const handleCollection = collection => {
      let pagePhotos = [];

      if (collection && collection.docs.length) {
        pagePhotos = collection.docs.map(getPhotoData);
        setNextPage(nextPage + 1);
      }

      setPhotos(prevState => ({
        ...prevState,
        [nextPage]: {
          collection,
          photos: pagePhotos,
          status: 'complete',
          end: pagePhotos.length === 0,
        }
      }));
    };

    return onSnapshot(query, collection => {
      handleCollection(collection);
    },
    err => {
      Sentry.captureException(err);
    }
    );
  };

  useInfiniteScroll(endOfContainerElement, getPage, {
    rootMargin: '400px 0px',
    threshold: 0,
  });

  useEffect(() => {
    getPage();
  }, []);

  return (
    <>
      {(photos[1] && photos[1].status === 'complete' && photos[1].collection.docs.length === 0) && props.renderZeroMessage()}

      <Grid
        container
        className={classes.photoGridContainer}
        spacing={4}
        {...props.gridProps}
      >
        <Protected allowedRoles={[...props.uploadRoles]}>

          <Grid
            key={'new-image'}
            item
            className={classes.photoGridItem}
            xs={12}
            md={6}
            lg={4}
            xl={3}
            {...props.gridItemProps}
          >
            <Paper className={classes.photoPaper}>
              <ImageUploader
                multiple
                buttonText="Add photos"
                onDrop={onDrop}
              />
            </Paper>
          </Grid>

        </Protected>

        {
          Object.keys(photos).map((page) => {
            const photoPage: PagedPhotosPage = photos[page];

            if (photoPage.status === 'loading' && props.showSkeleton && props.filter === 'all') {
              return (
                <PhotoGridSkeleton
                  key={page}
                  numItems={props.perPage}
                />
              );
            }

            if (photoPage?.collection?.docs?.length) {
              return photoPage.collection.docs.map((doc: DocumentSnapshot<guesthouse.Photo>) => {
                const photo = getPhotoData(doc);

                if (!photo || (props.filter === 'favorites' && !photo.favorite)) {
                  return null;
                }

                const roomLink = makeRoomLink({ home: photo.home, room: photo.room });

                const photoID = photo.docID;
                const parentID = doc.ref.parent.parent.id;
                let children = null;

                if (photo.status === 'PROCESSING') {
                  children = (
                    <>
                      <Protected allowedRoles={props.uploadRoles}>
                        <div className={classes.deletePhoto}>
                          <IconButton
                            onClick={() => {
                              asyncRender(ConfirmDialog, {
                                title: 'Are you sure you want to delete this photo?',
                                confirmText: 'Yes, Delete',
                              })
                                .then(() => deleteDoc(doc.ref))
                                .then(() => notificationContext.setContext({ open: true, message: 'Photo deleted' }))
                                .catch((e) => {
                                  if (e) {
                                    Sentry.captureException(e);
                                    notificationContext.setContext({ open: true, message: e.message, severity: 'error' });
                                  }
                                });
                            }}
                          >
                            <IconDelete fontSize="small" />
                          </IconButton>
                        </div>
                      </Protected>

                      {
                        uploads[photoID]?.preview
                          ? <img
                              src={uploads[photoID].preview.toString()}
                              className={classes.photoImg}
                              onClick={() => {
                                setSelectedPhoto(photo);
                                setOpen(true);
                              }}
                          />
                          : <Skeleton
                              variant="rectangular"
                              width="100%"
                              height="100%"
                          />
                      }
                    </>
                  );
                } else if (photo.status === 'UPLOADING') {
                  if (uploads[photoID]) {
                    children = (
                      <>
                        {uploads[photoID].preview && <img
                          src={uploads[photoID].preview.toString()}
                          className={classes.photoImg}
                        />}

                        <div
                          className={classes.photoUploadProgress}
                          style={{ width: `${100 - Math.floor(uploads[photoID].progress)}%` }}
                        />
                      </>
                    );
                  } else {
                    children = (
                      <>
                        <Protected allowedRoles={props.uploadRoles}>
                          <div className={classes.deletePhoto}>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteDoc(doc.ref)
                                  .then(() => {
                                    notificationContext.setContext({ open: true, message: 'Photo deleted' });
                                  })
                                  .catch(Sentry.captureException);
                              }}
                            >
                              <IconDelete fontSize="small" />
                            </IconButton>
                          </div>
                        </Protected>

                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="100%"
                        />
                      </>
                    );
                  }
                } else if (photo.status === 'ERROR') {
                  children = (
                    <>
                      <Protected allowedRoles={props.uploadRoles}>
                        <div className={classes.deletePhoto}>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteDoc(doc.ref)
                                .then(() => {
                                  notificationContext.setContext({ open: true, message: 'Photo deleted' });
                                })
                                .catch(Sentry.captureException);
                            }}
                          >
                            <IconDelete fontSize="small" />
                          </IconButton>
                        </div>
                      </Protected>

                      <Typography>
                        Something has gone wrong.
                      </Typography>
                    </>
                  );
                } else {
                  children = (
                    <>
                      <Protected allowedRoles={props.uploadRoles}>
                        {props.allowPrimaryPhoto && (
                          <div className={classes.setPrimary}>
                            <Tooltip
                              title="Make Primary"
                              onClick={async (e) => {
                                e.stopPropagation();

                                const primaryPhoto = convertPhotoToPrimaryPhoto(photo);

                                try {
                                  if (photo.type === 'room') {
                                    const homeID = photo.home?.docID;

                                    if (photo['room'].primaryPhoto?.docID === photo.docID && props.allowPrimaryPhotoDelete) {
                                      await updateDoc(firestoreDoc(collection(database, `homes/${homeID}/rooms`), parentID), { primaryPhoto: null });
                                    } else {
                                      await updateDoc(firestoreDoc(collection(database, `homes/${homeID}/rooms`), parentID), { primaryPhoto: primaryPhoto });
                                    }
                                  } else if (photo[photo.type]?.primaryPhoto?.docID === photo.docID && props.allowPrimaryPhotoDelete) {
                                    await updateDoc(firestoreDoc(collection(database, photo.type + 's'), parentID), { primaryPhoto: deleteField() });
                                  } else {
                                    await updateDoc(firestoreDoc(collection(database, photo.type + 's'), parentID), { primaryPhoto: primaryPhoto });
                                  }

                                  notificationContext.setContext({ open: true, message: 'Updated primary photo' });
                                } catch (e) {
                                  Sentry.captureException(`Failed to set primary photo: ${e.message}`);
                                  notificationContext.setContext({ severity: 'error', open: true, message: 'Could not update primary photo' });
                                }
                              }}
                            >
                              <IconButton
                                aria-label="make-primary"
                                className={classes.actionButton}
                              >
                                {
                                  (product || room || home || roomSet)?.primaryPhoto?.docID === photo.docID
                                    ? (
                                      <IconStar className={classes.actionIcon} />
                                    ) : (
                                      <IconStarBorder className={classes.actionIcon} />
                                    )
                                }
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}

                        <div className={classes.deletePhoto}>
                          <Tooltip
                            title="Delete Photo"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteDoc(doc.ref)
                                .then(() => {
                                  getPage();
                                });
                            }}
                          >
                            <IconButton className={classes.actionButton}>
                              <IconDelete className={classes.actionIcon} />
                            </IconButton>
                          </Tooltip>
                        </div>

                      </Protected>
                      

                      <div className={classes.favoritePhoto}>
                        <Tooltip
                          title={photo.favorite ? 'Remove from favorites' : 'Add to favorites'}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateDoc(doc.ref, { favorite: !photo.favorite });
                          }}
                        >
                          <IconButton className={classes.actionButton}>
                            {photo.favorite ? (
                              <IconFavorite className={classes.actionIcon} />
                            ) : (
                              <IconFavoriteBorder className={classes.actionIcon} />
                            )}
                          </IconButton>
                        </Tooltip>
                      </div>
                      <LazyLoadImage
                        width="100%"
                        height="100%"
                        src={photo.medium_url}
                        className={classes.photoImg}
                        threshold={100}
                        effect="opacity"
                        placeholder={<Skeleton
                          variant="rectangular"
                          style={{ margin: 0, height: '100%', width: '100%' }}
                        />}
                        onClick={() => {
                          setSelectedPhoto(photo);
                          setOpen(true);
                        }}
                      />

                      <div className={classes.productTags}>
                        {showProductTags && photoID && (
                          <div className={classes.productTagsWrapper}>
                            <ProductTags photoId={photoID} />
                          </div>
                        )}

                        {roomLink?.url && showHomeInfo && (
                          <div className={classes.roomLinkWrapper}>
                            <Chip
                              component={LinkBehavior}
                              href={roomLink.url}
                              label={roomLink.title}
                              icon={<IconHome className={classes.homeIcon} />}
                              className={classes.homeChip}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  );
                }

                return (
                  <Grid
                    key={photoID}
                    item
                    className={classes.photoGridItem}
                    xs={12}
                    md={6}
                    lg={4}
                    xl={3}
                    {...props.gridItemProps}
                  >
                    <Paper className={classes.photoPaper}>
                      {children}
                    </Paper>
                  </Grid>
                );
              });

            }
          })
        }

        {props.afterGrid && (
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
          >
            {props.afterGrid(Object.keys(photos).reduce((acc, cur) => {
              if (Array.isArray(photos[cur].photos)) {
                return [...acc, ...photos[cur].photos];
              }
              return acc;
            }, []))}
          </Grid>
        )}
      </Grid>

      {props.useInfiniteScroll && (<ScrollElement ref={endOfContainerElement} />)}

      <Dialog
        fullScreen
        open={open}
        maxWidth={false}
        style={{
          height: '100%',
        }}
        PaperProps={{
          style: {
            height: '100%',
          }
        }}
        onClose={() => {
          setOpen(false);
          setSelectedPhoto(null);
        }}
      >
        <PhotoLightBox
          photos={photosFlattened}
          selectedPhotoID={selectedPhoto?.docID}
          setSelectedPhotoID={(id: string) => setSelectedPhoto(photosFlattened.find(p => p?.docID === id))}
          renderImage={({ photo }) => {
            if (photo) {
              return (
                <TaggedProductsImage
                  photoTagsPath={`${photosCollection}/${photo.docID}/productTags`}
                  photo={photo}
                  src={photo.large_url}
                  style={{
                    cursor: enableTagging ? `url(${tagCursor}) 0 0, crosshair` : undefined,
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                  }}
                  renderTooltip={(productTag) => {
                    const { product } = productTag;

                    return (
                      <ProductCard
                        showMaker
                        className={classes.productCard}
                        product={product as guesthouse.Product}
                        externalLink={makeStoreUrl(`/shop/products/${slugOrID(product)}`)}
                      />
                    );
                  }}
                  onClick={enableTagging ? (e) => {
                    const image = e.target as HTMLImageElement;
                    const imageRect = image.getBoundingClientRect();

                    const clickX = e.clientX - imageRect.x;
                    const clickY = e.clientY - imageRect.y;

                    const parentSize = { width: imageRect.width, height: imageRect.height };
                    const childSize = { width: image.naturalWidth, height: image.naturalHeight };

                    const fitted = fit(parentSize, childSize, 'contain');

                    const offsetX = (parentSize.width - fitted.width) / 2;
                    const offsetY = (parentSize.height - fitted.height) / 2;

                    const positionX = round((clickX - offsetX) / fitted.width, 2);
                    const positionY = round((clickY - offsetY) / fitted.height, 2);

                    if (selectedProductTag) {
                      const productTag: Partial<guesthouse.ProductTag> = {
                        positionX,
                        positionY,
                      };

                      return updateDoc(
                        firestoreDoc(
                          collection(database, `${photosCollection}/${photo.docID}/productTags`),
                          selectedProductTag.product.docID
                        ),
                        productTag)
                        .then(() => setSelectedProductTag(undefined))
                        .catch((e) => {
                          Sentry.captureException(`Failed to update product tag: ${e.message}`);
                          notificationContext.setContext({ severity: 'error', open: true, message: 'Could not update product tag' });
                        });
                    } else {
                      asyncRender(ProductSearchDialog, {
                        userContext,
                        appContext,
                        title: 'Tag product',
                        showFilters: false,
                        extraFacetFilters: {
                          stagedHomes: home?.docID,
                        }
                      })
                        .then(async (product) => {
                          const home = photo?.home;
                          const room = photo?.room;

                          delete photo.home;
                          delete photo.room;

                          delete product.pastHomes;
                          delete product.currentHomes;
                          delete product.futureHomes;


                          const productTag: guesthouse.ProductTag = {
                            photo,
                            home,
                            room,
                            product,
                            positionX,
                            positionY,
                            created: new Date(),
                            notificationSent: false,
                          };

                          return setDoc(
                            firestoreDoc(
                              collection(database, `${photosCollection}/${photo.docID}/productTags`),
                              product.docID
                            ),
                            productTag)
                            .catch((e) => {
                              Sentry.captureException(`Failed to tag product: ${e.message}`);
                              notificationContext.setContext({ severity: 'error', open: true, message: 'Could not tag product' });
                            });
                        })
                        .catch((e) => Sentry.captureException(e));
                    }
                  } : undefined}
                />
              );
            }
          }}
          renderInteractions={() => {
            return (
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                {
                  sm ? (
                    <Button
                      variant="text"
                      color="white"
                      size="small"
                      startIcon={<IconClear />}
                      style={{ marginRight: theme.spacing(2) }}
                      onClick={() => {
                        setOpen(false);
                        setSelectedPhoto(null);
                      }}
                    >
                      Close
                    </Button>
                  ) : (
                    <IconButton
                      aria-label="close dialog"
                      onClick={() => {
                        setOpen(false);
                        setSelectedPhoto(null);
                      }}
                    >
                      <IconClear
                        style={{
                          width: 15,
                          height: 'auto',
                          stroke: '#fff',
                        }}
                      />
                    </IconButton>
                  )
                }

                {roomLink && (
                  <Chip
                    component={LinkBehavior}
                    href={roomLink.url}
                    label={roomLink.title}
                    icon={(
                      <IconHome
                        style={{
                          width: 20,
                          height: 'auto',
                          stroke: '#fff',
                        }}
                      />
                    )}
                    className={classes.homeChip}
                  />
                )}

                {
                  selectedProductTag && enableTagging && (
                    <span>
                      Currently editing tag for
                      {' '}
                      {selectedProductTag?.product?.title}
                      {' '}
                      <IconButton
                        aria-label="cancel tagging"
                        onClick={() => {
                          setOpen(false);
                          setSelectedProductTag(undefined);
                        }}
                      >
                        <IconClear
                          style={{
                            width: 15,
                            height: 'auto',
                            stroke: '#fff',
                          }}
                        />
                      </IconButton>
                    </span>
                  )
                }

                {
                  sm ? (
                    <Button
                      variant="outlined"
                      color="white"
                      size="xs"
                      startIcon={(
                        <IconArrowDownward
                          style={{
                            width: 15,
                            height: 'auto',
                            stroke: '#fff',
                          }}
                        />
                      )}
                      onClick={() => downloadFile(selectedPhoto.download_url, selectedPhoto.filename)}
                    >
                      Download
                    </Button>
                  ) : (
                    <IconButton
                      aria-label="download photo"
                      onClick={() => downloadFile(selectedPhoto.download_url, selectedPhoto.filename)}
                    >
                      <IconArrowDownward
                        style={{
                          width: 15,
                          height: 'auto',
                          stroke: '#fff',
                        }}
                      />
                    </IconButton>
                  )
                }
              </Box>
            );
          }}

          renderSidebar={showProductTags ? ({ photo }) => {
            let title = '';

            if (photo?.home) {
              title = shortAddress(photo?.home?.address);

              if (photo.room && !enableTagging) {
                title += ` (${photo.room.title})`;
              }
            }

            return (
              <Box
                width="100%"
              >
                <Box
                  display="flex"
                  width="100%"
                  alignItems="center"
                  style={{ marginBottom: theme.spacing(2) }}
                >
                  {title && (
                    <Typography
                      variant="h5Alt"
                      component="span"
                      style={{
                        fontWeight: 600,
                        marginRight: theme.spacing(1),
                        fontSize: 18,
                        flex: 1,
                      }}
                    >
                      {title}
                    </Typography>
                  )}

                  {
                    photo?.home && enableTagging && (
                      <RoomSelect
                        key={photo.docID}
                        style={{ flex: 1 }}
                        home={photo.home}
                        defaultRoom={photo.room}
                        onRoomChange={(room) => {
                          if (photo.docID) {
                            getDocs(firestoreQuery(collectionGroup(database, 'photos')
                              ,where('docID', '==', photo.docID)
                              ,limit(1)))
                              .then((snap) => {
                                snap.forEach((doc) => {
                                  updateDoc(doc.ref, { room });
                                });
                              });
                          }
                        }}
                      />
                    )
                  }

                  {
                    photo?.room && (
                      <Tooltip title={`Make primary photo for ${photo.room.title}`}>
                        <span>
                          <IconButton
                            aria-label="make-primary"
                            size="xs"
                            color="primary"
                            style={{
                              color: theme.palette.common.black,
                              fill: theme.palette.common.black,
                            }}
                            onClick={async () => {
                              const updatedRoom: Partial<guesthouse.Room> = {
                                primaryPhoto: convertPhotoToPrimaryPhoto(photo)
                              };

                              return await updateDoc(firestoreDoc(collection(database, `homes/${photo.room.home.docID}/rooms`), photo.room.docID), updatedRoom)
                                .then(() => {
                                  notificationContext.setContext({ open: true, message: `Updated primary photo for ${photo.room.title}` });
                                })
                                .catch(e => {
                                  Sentry.captureException(`Failed to set primary photo for room: ${e.message}`);
                                  notificationContext.setContext({ severity: 'error', open: true, message: `Could not update primary photo for ${photo.room.title}` });
                                });
                            }}
                          >
                            {
                              photo.room?.primaryPhoto?.docID === photo.docID
                                ? (
                                  <IconStar />
                                ) : (
                                  <IconStarBorder />
                                )
                            }
                          </IconButton>
                        </span>
                      </Tooltip>
                    )
                  }
                </Box>

                <TaggedProducts
                  photo={photo}
                  setSelectedProductTag={setSelectedProductTag}
                />
              </Box>
            );
          } : undefined}
        />
      </Dialog>
    </>
  );
};

PhotoGrid.defaultProps = defaultProps;

export default PhotoGrid;
